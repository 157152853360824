import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import { UserReducer } from './user/user.reducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    user:UserReducer,
});

export default reducer;

