import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import UserView from "./UserView";
import User from "./User";

 

const UserRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/users",
            element:<User/>
        },
        {
            path:"/users/view/:userId",
            element:<UserView/>
        },
    ]
}

export default UserRoutes;