import dashboard from './dashboard';
import user_management from './user_management';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard,user_management]
};

export default menuItems;
