import { useSelect } from "@mui/base";
import { Button,Icon,IconButton } from "@mui/material";
import useAlert from "hooks/useAlert" 
import useSnackbar from "hooks/useSnackbar";
import { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import SimpleSwitch from "ui-component/SimpleSwitch";
import Datatable from "ui-component/tables/Datatable";

import { isObjectEmpty } from "utils/helper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useAuth from "hooks/useAuth";
import { userList } from "services/api/user";
import { setUserList } from "store/user/user.action";
import { selectUser } from "store/user/user.selector";
const User = () => {
  const title = "User";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateState } = useAlert();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const setUser = () => {
    userList()
      .then((res) => {
        dispatch(setUserList(res.data.result.data));
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };
const {user}=useAuth();
  useEffect(() => {
    setLoading(true);
    setUser();
    setLoading(false);
  }, []);

  
  const handleView = (id, row) => {
    navigate("/users/view/" + row.rowData[0]);
  };

  const users = useSelector(selectUser);
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: true,
        sortOrder: 'desc',
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "company_name",
      label: "Company Name",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      label: "Create Date",
      options: {
        filter: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleView(e, tableMeta);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const data =  isObjectEmpty(users)
    ? []
    : users.map((item, index) => {
        return [
          item.id,
          index += 1,
          item.name,
          item.company_name,
          item.status == 1 ? 'Active' : 'Inactive',
          item.created_at,
        ];
      });

  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };
  return (
  <Datatable
    title={title}
    data={data}
    columns={columns}
    options={options}
    isLoading={loading}
  />);
};
export default User;
