import { Box, Typography, styled } from '@mui/material';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { userList } from 'services/api/user';
import { setUserList } from 'store/user/user.action';
import { selectUser } from 'store/user/user.selector';
import Breadcrumb from 'ui-component/Breadcrumb'
import GoogleMap from 'ui-component/GoogleMap';
import SimpleCard from 'ui-component/SimpleCard'

const UserView = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { openSnackbar } = useSnackbar();
    const { userId } = useParams();


    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));
    const setUser = () => {
        userList()
            .then((res) => {
                dispatch(setUserList(res.data.result.data));
            })
            .catch((error) => {
                openSnackbar("error", error.data.message);
            });
    };

    useEffect(() => {
        setLoading(true);
        setUser();
        setLoading(false);
    }, []);

    const users = useSelector(selectUser);
    let currentUser = users.filter((item) => item.id == userId);
console.log(currentUser)
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: 'User', path: "/users" }, { name: `User view` }]} />
            </Box>
            <SimpleCard title="User view">
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBlock: 2 }}>
                    <Typography variant="h3" component="h2" color="primary">
                        User Name: {currentUser[0].name}
                    </Typography>
                    <Typography variant="h3" component="h2" color="secondary">
                        Company Name: {currentUser[0].company_name}
                    </Typography>
                </Box>
                <GoogleMap latitude={Number(currentUser[0].latitude)} longitude={Number(currentUser[0].longitude)} />
            </SimpleCard>
        </Container>
    )
}

export default UserView
