import React from 'react';
import GoogleMapReact from 'google-map-react';

const GoogleMap = ({latitude, longitude}) => {
  // Set your Google Maps API key here
  const apiKey = 'AIzaSyAS1kzHr_O2Qg6cac3qn2X8WhdqhADAiRc';

  // Set the default location (for example, a city's coordinates)
  const defaultCenter = {
    lat: latitude, // Default latitude
    lng: longitude, // Default longitude
  };

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
    position: { lat: latitude, lng: longitude },
    map,
    title: 'Hello World!'
    });
    return marker;
   };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={defaultCenter}
        defaultZoom={10} // Adjust the default zoom level as needed
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      >
      
      </GoogleMapReact>
    </div>
  );

  const Marker = props => {
    return <div className="SuperAwesomePin"></div>
  }
};

export default GoogleMap;
