import { IconUser } from '@tabler/icons';

const user_management={
 id:'user_management',
 title:'User    ',
 type:'group',
 children:[
     {
         id:'user',
         title:'User',
         type:'item',
         url:'/users',
         icon:IconUser,
    }
 ]
}

export default user_management;